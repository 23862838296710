
import jQuery from "jquery";
const $ =  jQuery;

$(document).ready(function() {
	var clipboard = new ClipboardJS('.jitsi-clipboard');

	clipboard.on('success', function(e) {
		console.log("Link copied to clipboard!");
	});

	clipboard.on('error', function(e) {
		console.log("Failed to copy link!");
	});
});
