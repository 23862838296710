let fieldsetElement;
let inputElement;

export default () => {
  fieldsetElement = document.querySelector('fieldset#workshop');
  inputElement = document.querySelector(
    '#time_slot_workshop_attributes_participant_count'
  );

  const selectElement = document.querySelector('#time_slot_meeting_category');
  selectElement.onchange = event => handleSelectChange(event);
};

const handleSelectChange = event => {
  const category = event.target.value;
  if (category == 'workshop') {
    fieldsetElement.setAttribute('style', '');
    inputElement.disabled = false;
  } else {
    fieldsetElement.setAttribute('style', 'display: none;');
    inputElement.disabled = true;
  }
};
