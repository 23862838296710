let tableElement;
let titleSelectElement;
let organizationSelectElement;

export default () => {
  tableElement = document.querySelector('[data-delegate-table]');

  const inputElement = document.querySelector('[data-delegate-search]');
  if (inputElement) {
    inputElement.oninput = event => handleInputChange(event);
  }

  titleSelectElement = document.querySelector('[data-delegate-filter-title]');
  if(titleSelectElement) {
    titleSelectElement.onchange = event => handleTitleSelectChange(event);
  }

  organizationSelectElement = document.querySelector(
    '[data-delegate-filter-organization]'
  );
  if (organizationSelectElement) {
    organizationSelectElement.onchange = event =>
      handleOrganizationSelectChange(event);
  }
};

const handleInputChange = event => {
  const query = event.target.value;
  const tableRows = tableElement.querySelector('tbody').querySelectorAll('tr');
  for (let i = 0; i < tableRows.length; ++i) {
    const organization = tableRows[i].dataset.organization;
    const title = tableRows[i].dataset.title;
    const name = tableRows[i].dataset.name;

    if (
      query === '' ||
      queryMatches(organization, query) ||
      queryMatches(title, query) ||
      queryMatches(name, query)
    ) {
      tableRows[i].setAttribute('style', '');
    } else {
      tableRows[i].setAttribute('style', 'display: none;');
    }
  }

  organizationSelectElement.value = '';
  titleSelectElement.value = '';
};

const handleTitleSelectChange = event => {
  const title = event.target.value;
  const tableRows = tableElement.querySelector('tbody').querySelectorAll('tr');
  for (let i = 0; i < tableRows.length; ++i) {
    if (tableRows[i].dataset.title !== title && title !== '') {
      tableRows[i].setAttribute('style', 'display: none;');
    } else {
      tableRows[i].setAttribute('style', '');
    }
  }

  organizationSelectElement.value = '';
};

const handleOrganizationSelectChange = event => {
  const organization = event.target.value;
  const tableRows = tableElement.querySelector('tbody').querySelectorAll('tr');
  for (let i = 0; i < tableRows.length; ++i) {
    if (
      tableRows[i].dataset.organization !== organization &&
      organization !== ''
    ) {
      tableRows[i].setAttribute('style', 'display: none;');
    } else {
      tableRows[i].setAttribute('style', '');
    }
  }

  titleSelectElement.value = '';
};

const queryMatches = (str, query) => {
  const regexp = new RegExp(query, 'i');
  return str.search(regexp) !== -1;
};
